<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.list' }">
                {{ this.$t('menu.company_dashboard.assignments') }}
              </router-link>
            </li>
            <li>{{ this.$t('menu.company_dashboard.create_assignment') }}</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>{{ this.$t('general.new_assignment') }}</span></h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="main-switcher"
          uk-switcher="connect: #main-switcher-tabs ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class=" p-4 header-a" href="#"><i class="icon-feather-flag"></i> <b>{{ this.$t('login.header') }}</b></a>
          </li>
          <li>
            <a class="p-4 header-a" href="#"><i class="uil-book-open"></i> <b>{{ this.$t('messages.content') }}</b></a>
          </li>
          <li>
            <a class="p-4 header-a" href="#"><i class="uil-users-alt"></i> <b>{{ this.$t('users.users') }}</b></a>
          </li>
          <li v-if="$isGiftVisible">
            <a class="p-4  header-a" href="#"><i class="uil-gift"></i> <b>{{ this.$t('general.gift') }}</b></a>
          </li>
          <li>
            <a class="p-4 header-a" href="#" @click="hideSomeInputsForClassRoomEducation"><i class="uil-cog"></i>
              <b>{{ this.$t('general.settings') }}</b></a>
          </li>
          <li>
            <a class="p-4 header-a" href="#"><i class="uil-invoice"></i> <b>{{ this.$t('general.overview') }}</b></a>
          </li>
        </ul>
        <default-loading v-show="assignment_loading"></default-loading>
        <div v-show="!assignment_loading">
          <div class="card-body">
            <ul class="uk-switcher uk-margin" id="main-switcher-tabs">
              <!--tab: özellikler-->
              <li>
                <div class="row">
                  <div class="col-xl-9 m-auto">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="course_title">{{ this.$t('general.assignment_name')
                        }}<span class="required">*</span></label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" v-model="name" id="course_title" required="" />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="short_description">{{
                this.$t('collectionDetail.content_description') }} <span class="required">*</span><i
                          class="uil-info-circle ml-1" uk-tooltip="title: Açıklama en fazla 1000 harf olabilir.."></i>
                        <br />({{ textarea_length }} / 1000)
                      </label>
                      <div class="col-md-9">
                        <textarea name="short_description" id="short_description" maxlength="1000" v-model="description"
                          class="form-control"></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="logo">{{ this.$t('collectionDetail.cover_image')
                        }}<span class="required">*</span></label>
                      <div class="col-md-9">

                        <div class="
                                          position-relative
                                          d-flex
                                          justify-center justify-content-center
                                        ">
                          <img style="height: 190px" class="rounded img-fluid logoarea"
                            src="@/assets/images/no-photo.png" alt="" />
                          <input type="file" class="d-none" id="cover_photo_input" accept="image/*"
                            @change="processFile($event)" />
                          <button @click="inputClick()" type="button" class="btn btn-default btn-icon-label"
                            style="top: 50%; position: absolute">
                            <span class="btn-inner--icon">
                              <i class="uil-image"></i>
                            </span>
                            <span class="btn-inner--text">{{
                $t("general.image_change")
              }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0 mb-3">
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(1)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>
              <!--tab: eğitim-->
              <li>
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="showModalLearning" id="modal_learning" class="uk-modal-full uk-modal" uk-modal="">
                      <div class="uk-modal-footer bggray">
                        <h3 class="float-left">
                          <i class="uil-presentation-plus"></i> İçerik Seç
                        </h3>
                        <button class="uk-button uk-button-default small uk-modal-close  float-right" type="button">
                          <i class="uil-times"></i> Kapat
                        </button>
                      </div>

                      <div class="uk-modal-dialog bggray h-100">
                        <Content @addNewEducation="addContentToAssignment($event)"
                          @removeEducation="removeContentFromAssignment($event)" :show_program="true" :show_exam="true"
                          :show_survey="true" :selected_items="all_selected_educations"></Content>
                      </div>
                    </div>

                    <div class="mb-2 mx-2 bggray px-2 pt-2 pb-3 card">
                      <div class="row text-right">
                        <div class="col-md-10">
                          <div class="row" style="display:flex;justify-content:end">
                            <label class="col-md-3 col-form-label">
                              Sıralı içerik olsun
                            </label>
                            <div class="col-md-1">
                              <label class="uk-switch" for="can_open_contents_in_order">
                                <input type="checkbox" id="can_open_contents_in_order"
                                  v-model="can_open_contents_in_order" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <button class="btn btn-info btn-sm mb-3" uk-toggle="target: #modal_learning">
                            <i class="uil-plus m-0"></i> İçerik Seç
                            <span class="required">*</span>
                          </button>
                        </div>
                      </div>
                      <div v-if="educations.length == 0" class="text-center mb-3">
                        İçerik seçilmedi
                      </div>
                      <draggable v-else class="uk-accordion-content mt-0" :list="educations" group="education_widget">
                        <div v-for="(education, index) in educations" :key="'assignment_content_list_' + index">
                          <div class="sec-list-item">
                            <div>
                              <i class="uil-list-ul mr-2"></i>
                              <p>{{ education.title }}</p>
                              <label class="mb-0 mx-2" style="
                                                      font-size: 0.75rem;
                                                      background: #3e416d;
                                                      padding: 1px 5px;
                                                      border-radius: 3px;
                                                      color: white;
                                                    ">{{ $t(education.name) }}</label>
                            </div>

                            <div>
                              <div class="btn-act">
                                <!-- <a href="#" uk-tooltip="title:Önizleme"
                                  ><i class="uil-eye"></i
                                ></a> -->
                                <a @click="showCustomize(education.id, index)">
                                  <i class="icon-feather-settings" style="margin-right: 2px;"></i>
                                  Özelleştir</a>
                                <a href="#" uk-tooltip="title:Kaldır"
                                  @click.prevent="removeContentFromAssignment(education, index)">
                                  <i class="icon-feather-trash"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </draggable>

                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(0)">
                        <i class="icon-feather-arrow-left"></i>Önceki
                      </button>
                      <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(2)">
                        <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <!--tab: kullanıcılar-->
              <li>
                <UserGroupSelection @setCheckedUsers="($event) => {
                checked_users = $event;
              }
                " @setCheckedGroups="($event) => {
                checked_groups = $event;
              }
                " :checked_users="checked_users" :checked_groups="checked_groups"></UserGroupSelection>

                <div class="uk-card-footer float-right pb-0 pr-0 mb-3">
                  <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(1)">
                    <i class="icon-feather-arrow-left"></i> Önceki
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(3)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>
              <!--tab: hediye-->
              <li v-if="$isGiftVisible">
                <div class="row">
                  <div class="col-md-3">
                    <div id="gift-mode" class="btn-night-mode">
                      <i class="icon-feather-gift"></i> {{ this.$t('general.give_a_gift_at_the_end') }}
                      <span class="btn-night-mode-switch">
                        <label class="uk-switch" for="default-1">
                          <input type="checkbox" id="default-1" v-model="has_present" />
                          <div class="uk-switch-slider"></div>
                        </label>
                      </span>
                    </div>
                    <div class="form-group row mt-3" v-if="has_present">
                      <div class="col-md-12">
                        <label class="col-form-label" for="course_title">
                          Hediye Verme Kuralı<span class="required">*</span></label>
                        <select class="selectpicker7" v-model="gift_rule_id">
                          <option value="1">En Hızlı Tamamlayanlar</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9" v-if="has_present">
                    <div class="uk-accordion-content mt-0">
                      <div id="modal_gifts" class="uk-modal-full uk-modal" uk-modal="">
                        <div class="uk-modal-footer bggray">
                          <h3 class="float-left">
                            <i class="uil-presentation-plus"></i>
                            {{ $t("gift.management") }}
                          </h3>
                          <button class="
                                            uk-button uk-button-default
                                            small
                                            uk-modal-close
                                            float-right
                                          " type="button">
                            <i class="uil-times"></i> Kapat
                          </button>
                        </div>

                        <div class="uk-modal-dialog bggray h-100">
                          <Gift @addNewGift="addGiftToAssignment($event)" :selected_items="all_selected_gifts"></Gift>
                        </div>
                      </div>
                      <ul class="sec-list gift">
                        <button class="btn btn-secondary mb-3 w-100" uk-toggle="target: #modal_gifts">
                          <i class="uil-plus m-0"></i> Hediye Ekle<span class="required">*</span>
                        </button>
                        <draggable :list="gifts" group="gifts_widget">
                          <li v-for="(item, index) in gifts">
                            <div class="sec-list-item" style="cursor: move">
                              <div>
                                <img :src="item.image" alt="" />
                                <p>{{ item.name }}</p>
                                <span style="
                                                  display: block;
                                                  font-size: 0.875rem;
                                                  color: #ae71ff;
                                                  margin-left: 0.4rem;
                                                ">(<i class="uil-user d-inline"></i>
                                  {{ index + 1 }}. kazanan)</span>
                              </div>
                              <div>
                                <div class="btn-act">
                                  <a href="#" @click.prevent="removeGiftFromAssignment(item.id)
                "><i class="icon-feather-x"></i></a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </draggable>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0 pr-0 mb-3">
                  <button class="uk-button uk-button-outline small mr-2" type="submit" @click="switcherChange(2)">
                    <i class="icon-feather-arrow-left"></i> Önceki
                  </button>
                  <button class="uk-button uk-button-primary small" type="submit" @click="validateAndSwitch(4)">
                    <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </li>
              <!--tab: ayarlar-->
              <li>
                <div class="container">
                  <div class="row">
                    <div class="col-md-3">
                      <ul class="uk-tab-right uk-tab sidemenu"
                        uk-switcher="connect: #settings-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                        <li class="uk-active">
                          <a href="#"><i class="uil-keyboard"></i>
                            <b>Atama Ayarları</b></a>
                        </li>
                        <li>
                          <a href="#"><i class="uil-envelope-edit"></i>
                            <b>Mesaj Ayarları</b></a>
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-9">
                      <ul class="uk-switcher" id="settings-tab">
                        <li class="uk-active">
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="course_title">
                              {{ this.$t('general.program_option') }}<span class="required">*</span></label>
                            <div class="col-md-6">
                              <select v-model="is_compulsory">
                                <option value="true">Zorunlu</option>
                                <option value="false">Zorunlu Değil</option>
                              </select>
                            </div>
                          </div>

                          <hr />

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="website_keywords">
                              {{ this.$t('general.feature_the_training') }}
                              <i class="uil-info-circle" uk-tooltip="title: Eğitimi öne çıkanlar listesine ekler"></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="default-one-cikanlar">
                                <input type="checkbox" id="default-one-cikanlar" v-model="highlight_education" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <hr />

                          <div class="form-group row mb-2 input-checkbox">
                            <div class="col-md-6">
                              <label class="col-form-label" for="pass">{{ this.$t('general.start_day') }}<span
                                  class="required">*</span>
                                <i class="uil-info-circle" uk-tooltip="title: Eğitim Başlangıç Tarihi"></i>
                              </label>
                              <input type="date" class="form-control" id="start_date" v-model="start_date"
                                :min="setMinimumDate()" :max="lastStartDate ? setMinimumDate(lastStartDate) : null" />
                            </div>
                            <div class="col-md-6">
                              <label class="col-form-label" for="pass">
                                Son Tamamlama Tarihi
                                <span class="required">*</span>
                                <i class="uil-info-circle"
                                  uk-tooltip="title:Bitiş tarihi eğitimin süresinin dolduğu tarihtir.O gün izlenme yapılamaz. "></i>
                              </label>
                              <input type="date" class="form-control" id="end_date" :min="lastEndDate
                ? setMinimumDate(lastEndDate)
                : setMinimumDate(start_date ?? null)
                " v-model="end_date" />
                            </div>

                            <div class="col-md-12">
                              <div class="form-group row mb-2" v-if="!isClassRoomEducationSelected">
                                <label class="col-md-6 col-form-label" for="website_keywords">{{
                this.$t('general.repetition') }}
                                  <i class="uil-info-circle"
                                    uk-tooltip="title: Kullanıcı içerikleri tamamladığında tekrar edebilmesi için bu seçenek seçilir">
                                  </i>
                                </label>
                                <div class="col-md-6">
                                  <label class="uk-switch" for="repeatable">
                                    <input type="checkbox" id="repeatable" v-model="repeatable" />
                                    <div class="uk-switch-slider"></div>
                                  </label>
                                </div>
                              </div>
                              <div class="form-group row mb-2" v-if="repeatable">
                                <label class="col-md-6 col-form-label" for="website_keywords">
                                  Tekrar Sayısı<span class="required">*</span>
                                  <i class="uil-info-circle"></i>
                                </label>
                                <div class="col-md-6">
                                  <input type="number" min="0" style="width: 100px" class="form-control"
                                    v-model="repetition_no" @keypress="isNumber($event)" />
                                </div>
                              </div>
                            </div>

                            <template v-if="repetition_no && repeatable">
                              <hr />
                              <template v-for="i in parseInt(repetition_no)">
                                <div v-if="'row_key' + i" class="row w-100 mb-3" :key="'row_key' + i">
                                  <div class="col-md-2 d-flex align-items-end justify-content-center"
                                    :key="'row_number_' + i">
                                    <span class="row_number">{{ i }}.</span>
                                  </div>
                                  <div class="col-md-5" :key="'start_date_index_' + i">
                                    <label class="col-form-label" for="pass">{{ $t('general.start_day') }}<span
                                        class="required">*</span>
                                      <i class="uil-info-circle" uk-tooltip="title: "></i></label>
                                    <input v-if="repetition_date[i]" type="date" class="form-control mb-0" id="pass"
                                      :min="setMinimumDate()" v-model="repetition_date[i].start_date" />
                                  </div>
                                  <div class="col-md-5" :key="'end_date_index_' + i">
                                    <label class="col-form-label" for="pass">
                                      Son Tamamlama Tarihi<span class="required">*</span>
                                      <i class="uil-info-circle" uk-tooltip="title: "></i>
                                    </label>
                                    <input v-if="repetition_date[i]" type="date" class="form-control mb-0" id="pass"
                                      :min="repetition_date[i].start_date
                ? setMinimumDate(
                  repetition_date[i].start_date
                )
                : setMinimumDate()
                " v-model="repetition_date[i].end_date" />
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                          <hr />

                          <div v-if="durationFeatureIsEnabled">
                            <div class="form-group domain row mb-2">
                              <label class="col-md-6 col-form-label" for="assigment_duration">
                                Süre <i class="uil-info-circle"></i>
                              </label>
                              <div class="col-md-6">
                                <div class="d-flex">
                                  <input id="assigment_duration" type="number" min="0" class="form-control flex-grow-1"
                                    v-model="duration" @keypress="isNumber($event)" /><span>{{ $t("general.minutes")
                                    }}</span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="survey_required" style="padding-right:0">{{
                this.$t('general.add_survey_after_the_training') }}
                              <i class="uil-info-circle"
                                uk-tooltip="title: Eğitim sonunda kullanıcıların eğitimi puanlamaları için gerekli olan ankettir."></i></label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="survey_required">
                                <input type="checkbox" id="survey_required" v-model="survey_required" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2" v-show="survey_required">
                            <label class="col-md-6 col-form-label" for="website_keywords">Değerlendirme anketi
                              seçiniz.<span class="required">*</span>
                            </label>
                            <div class="col-md-6">
                              <multiselect v-model="survey_id" tag-placeholder="Anket Seç" placeholder="Anket Seç"
                                label="name" track-by="id" :options="survey_items" :allow-empty="false"
                                :searchable="true" deselectLabel="" selectLabel="Seçiniz" />
                            </div>
                          </div>
                          <hr />

                          <div class="form-group row mb-2" v-if="!isClassRoomEducationSelected">
                            <label class="col-md-6 col-form-label" for="website_keywords">{{
                this.$t('general.show_the_top_rankings_list') }}
                              <i class="uil-info-circle"
                                uk-tooltip="title: Eğitimi en performanslı şekilde tamamlayan kullanıcıların listesidir. Eğitimin atandığı tüm kullanıcılar görebilir.">
                              </i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="appear_top">
                                <input type="checkbox" id="appear_top" v-model="appear_top" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="has_feedbacks">
                              {{ this.$t('general.experience_sharing') }}
                              <i class="uil-info-circle" uk-tooltip="title: Deneyim Paylaşımları."></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="has_feedbacks">
                                <input type="checkbox" id="has_feedbacks" v-model="has_feedbacks" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="can_be_watched_again">
                              {{ this.$t('general.allow_users_to_watch_the_training_again') }}
                              <i class="uil-info-circle"
                                uk-tooltip="title: Eğitimini tamamlamış ve süresi geçmiş bir eğitimi kullanıcı tekrar izleyebilsin mi?"></i>
                            </label>
                            <div class="col-md-6">
                              <label class="uk-switch" for="can_be_watched_again">
                                <input type="checkbox" id="can_be_watched_again" v-model="can_be_watched_again" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="send_mail_notification">E-Posta
                              Gönderilsin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="send_mail_notification">
                                <input type="checkbox" id="send_mail_notification" v-model="send_mail_notification" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                            <div class="col-md-12" v-if="send_mail_notification">
                              <p><input v-model="isRemindEntered" class="mr-2" type="checkbox" id="notification1"
                                  name="notification1">Eğitime
                                <input v-model="remind_if_not_entered_x_days" class="d-inline" value="1" type="number"
                                  min="1" max="365" name="notification1_day1" style="width:65px;height:35px;"> gün giriş
                                yapılmadıysa her
                                <input v-model="remind_if_not_entered_every_y_days" class="d-inline" value="1"
                                  type="number" name="notification1_day2" min="1" max="365"
                                  style="width:65px;height:35px;"> günde bir mesaj
                                gönder.
                              </p>
                              <p>
                                <input v-model="isRemindStarting" class="mr-2" type="checkbox" id="notification2"
                                  name="notification2">Eğitim
                                başlama tarihinden
                                <input v-model="remind_before_starting_x_days" class="d-inline" value="1" type="number"
                                  min="1" max="365" name="notification2_day1" style="width:65px;height:35px;"> gün önce
                                her
                                <input v-model="remind_before_starting_every_y_days" class="d-inline" value="1"
                                  type="number" name="notification2_day2" min="1" max="365"
                                  style="width:65px;height:35px;"> günde bir mesaj
                                gönder.
                              </p>
                              <p><input v-model="isRemindEnding" class="mr-2" type="checkbox" id="notification3"
                                  name="notification3">Eğitimin bitiş tarihinden
                                <input v-model="remind_before_ending_x_days" class="d-inline" value="1" type="number"
                                  min="1" max="365" name="notification2_day1" style="width:65px;height:35px;"> gün önce
                                her
                                <input v-model="remind_before_ending_every_y_days" class="d-inline" value="1"
                                  type="number" name="notification2_day2" min="1" max="365"
                                  style="width:65px;height:35px;"> günde bir mesaj
                                gönder.
                              </p>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="send_mobile_notification">Mobil Bildirim
                              Gönderilsin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="send_mobile_notification">
                                <input type="checkbox" id="send_mobile_notification"
                                  v-model="send_mobile_notification" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="can_be_added_to_calendar">Takvim'e
                              Eklensin</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="can_be_added_to_calendar">
                                <input type="checkbox" id="can_be_added_to_calendar"
                                  v-model="can_be_added_to_calendar" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="form-group row mb-2">
                            <label class="col-md-6 col-form-label" for="has_customized_template">E-posta Şablonunu
                              Özelleştir</label>
                            <div class="col-md-6 text-right">
                              <label class="uk-switch" for="has_customized_template">
                                <input type="checkbox" id="has_customized_template" v-model="has_customized_template" />
                                <div class="uk-switch-slider"></div>
                              </label>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-6" v-if="has_customized_template">
                              <button class="
                                              btn btn-secondary
                                              uk-button uk-button-default
                                              mb-3
                                            " id="uk_button_mail_codes" type="button">
                                <i class="uil-plus-circle"></i> Kod Bloğu Ekle
                              </button>
                            </div>

                            <div class="uk-width-large" uk-dropdown="toggle:#uk_button_mail_codes">
                              <div class="uk-dropdown-grid uk-child-width-1-2@m" uk-grid>
                                <div>
                                  <ul class="uk-nav uk-dropdown-nav">

                                    <template v-for="(code, i) in mail_template_codes">
                                      <template v-if="i < codeSlice()">
                                        <li class="uk-active">
                                          <a href="#">{{
                $t(code.key_name)
              }}</a>
                                        </li>
                                        <li>
                                          <b>{{ code.code }}</b>
                                          <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                            {{ $t("general.copy") }}</a>
                                        </li>
                                        <li class="uk-nav-divider"></li>
                                      </template>
                                    </template>
                                  </ul>
                                </div>
                                <div>
                                  <ul class="uk-nav uk-dropdown-nav">

                                    <template v-for="(code, i) in mail_template_codes">
                                      <template v-if="i >= codeSlice()">
                                        <li class="uk-active">
                                          <a href="#">{{ code.name }}</a>
                                        </li>
                                        <li>
                                          <b>{{ code.code }}</b>
                                          <a href="#" @click="copyCode(code.code)"><i class="uil-copy-alt"></i>
                                            {{ $t("general.copy") }}</a>
                                        </li>
                                        <li class="uk-nav-divider"></li>
                                      </template>
                                    </template>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <html-area v-if="mail_template.html != undefined &&
                has_customized_template
                " :model="mail_template" :company="company" @update="updateMailTemplate"></html-area>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="uk-card-footer float-right pb-0 pr-0 pr-0">
                    <button class="uk-button uk-button-outline small mr-2" type="submit"
                      @click="switcherChange($isGiftVisible ? 3 : 2)">
                      <i class="icon-feather-arrow-left"></i> Önceki
                    </button>
                    <button class="uk-button uk-button-primary small" type="submit"
                      @click="validateAndSwitch($isGiftVisible ? 5 : 4)">
                      <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                    </button>
                  </div>
                </div>
              </li>
              <!--tab: sonuç-->
              <li>
                <div class="row">
                  <div class="col-12 my-lg-5">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="icon-feather-check-circle text-success"></i>
                      </h2>
                      <h3 class="mt-0">Atama Hazır !</h3>

                      <div class="col-md-6 offset-md-3">
                        <table class="uk-table uk-table-divider result-table">
                          <tbody>
                            <tr>
                              <td class="text-center" colspan="2">
                                <img class="logoarea rounded img-fluid" src="@/assets/images/no-photo.png" alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">{{ this.$t('general.assignment_name') }}:</td>
                              <td class="text-left">
                                <b>{{ name }}</b>
                              </td>
                            </tr>
                            <!-- <tr>
                          <td class="text-right">{{ $t('general.training_name') }}:</td>
                          <td class="text-left">
                            <b>İş Sağlığı ve Güvenliği</b>
                          </td>
                        </tr> -->
                            <tr v-if="checked_users.length > 0">
                              <td class="text-right">
                                {{ this.$t('general.number_of_assigned_users') }}:
                              </td>
                              <td class="text-left">
                                <b>{{ checked_users.length }}</b>
                              </td>
                            </tr>
                            <tr v-if="checked_groups.length > 0">
                              <td class="text-right">Atanan Grup Sayısı:</td>
                              <td class="text-left">
                                <b>{{ checked_groups.length }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">{{ this.$t('general.give_a_gift_at_the_end') }}:</td>
                              <td class="text-left">
                                <b>{{
                has_present
                  ? $t("general.yes")
                  : $t("general.no")
              }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">
                                {{ this.$t('general.top_rankings_list_display') }}:
                              </td>
                              <td class="text-left">
                                <b>{{
                appear_top
                  ? $t("general.yes")
                  : $t("general.no")
              }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">{{ this.$t('general.start_day') }}:</td>
                              <td class="text-left">
                                <b>{{ start_date }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">Son Tamamlama Tarihi:</td>
                              <td class="text-left">
                                <b>{{ end_date }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="qrCodeCanBeShown">
                          <QrCodeModal :modal_title="qor_code_modal_title"
                            :modal_name="'qr_code_for_classroom_education'" :hideSubmit="true" :qr_text="qr_text"
                            :showCloseBtn="false"></QrCodeModal>
                        </div>
                      </div>

                      <p class="w-75 mb-2 mx-auto" style="font-size: 0.875rem">
                        Kaydet butonuna tıklayarak<br />seçili kullanıcılara
                        atamayı gerçekleştirebilirsiniz.
                      </p>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0 mb-3">
                  <button class="uk-button uk-button-outline small mr-2" type="button"
                    @click="switcherChange($isGiftVisible ? 4 : 3)">
                    <i class="icon-feather-arrow-left"></i> Önceki
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                    <i class="icon-feather-arrow-right"></i> Kaydet
                  </button>
                </div>
              </li>
            </ul>
          </div>


        </div>
      </div>

    </div>

    <modal name="qr_code_for_classroom_education" :adaptive="false" height="auto" width="700">
      <QrCodeModal :modal_title="qor_code_modal_title" :modal_name="'qr_code_for_classroom_education'"
        :hideSubmit="true" :qr_text="qr_text"></QrCodeModal>
    </modal>
    <div id="modal_customize" style="z-index: 9999999;" uk-modal class="uk-flex-center">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="row" style="margin-top:1rem;margin-bottom: 1rem;margin-right: 1rem;">
          <div class="col-md-3" style="padding-left: 20px;">
            <ul class="uk-tab-right uk-tab sidemenu"
              uk-switcher="connect: #contentSetting ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li class="uk-active">
                <a href="#"><i class="uil-keyboard"></i>
                  <b>İçerik Ayarları</b></a>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <ul class="uk-switcher" id="contentSetting">
              <li class="uk-active">
                <div class="row">
                  <div class="col-md-12" style="padding:0">
                    <label class="uk-form-label">
                      İçerik Özelliği
                    </label>
                    <select class="form-control" v-model="selectedContentFeature">
                      <option v-for="contentFeature in content_features" :value="contentFeature.type">
                        {{
                contentFeature.name
              }} </option>
                    </select>
                  </div>

                  <template v-if="selectedContentFeature === 'timed_content'">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="pass">
                          Başlangıç Tarihi
                          <span class="required">*</span>
                        </label>
                        <VueCtkDateTimePicker :format="'YYYY-MM-DD HH:mm:ss'"
                          v-model="contentsTimed[selectedEducationId].starts_at" :min-date="setMinimumDate()"
                          :max-date="contentsTimed[selectedEducationId].ends_at" />
                      </div>
                    </div>
                  </template>
                </div>
                <div class="row" v-if="selectedContentFeature === 'timed_content'">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="col-form-label" for="pass">
                        Bitiş Tarihi
                        <span class="required">*</span>
                      </label>
                      <VueCtkDateTimePicker :format="'YYYY-MM-DD HH:mm:ss'" :no-button-now="true"
                        v-model="contentsTimed[selectedEducationId].ends_at"
                        :min-date="contentsTimed[selectedEducationId].starts_at" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row" style="float:right" v-if="selectedContentFeature === 'timed_content'">
              <button class="uk-button uk-button-outline small mr-2" type="button">
                <i class="icon-feather-arrow-left"></i>Vazgeç
              </button>
              <button class="uk-button uk-button-primary small" :disabled="!controlContentRequired" type="button"
                @click="saveContentFeatures()">
                <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
import Content from "@//view/components/assignment/Content";
import Gift from "@//view/components/assignment/Gift";
import HtmlArea from "@/view/company/Mail/Html";
import Multiselect from "vue-multiselect";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import QrCodeModal from "@/view/components/assignment/QrCodeModal";

import assignment_module, {
  BASE_URL as ASSIGNMENT_BASE_URL,
  CREATE_ITEM as ASSIGNMENT_CREATE_ITEM,
  ERROR as ASSIGNMENT_ERROR,
  ITEM as ASSIGNMENT_ITEM,
  MODULE_NAME as ASSIGNMENT_MODULE_NAME,
} from "@/core/services/store/assignment.module";

import survey_module, {
  BASE_URL as SURVEY_BASE_URL,
  GET_ITEMS as SURVEY_GET_ITEMS,
  ITEMS as SURVEY_ITEMS,
  MODULE_NAME as SURVEY_MODULE_NAME,
} from "@/core/services/store/evaluation_survey.module";

import { GET_ITEM_DETAIL_BY_ID } from "@/core/services/store/REST.module";

const _SURVEY_MODULE = SURVEY_MODULE_NAME;
const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE_NAME;

export default {
  name: "AddAssignment",
  components: {
    UserGroupSelection,
    draggable,
    Content,
    Gift,
    Multiselect,
    HtmlArea,
    DefaultLoading,
    QrCodeModal,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_SURVEY_MODULE, survey_module);
    registerStoreModule(_ASSIGNMENT_MODULE, assignment_module);
  },
  data() {
    return {
      qrCodeCanBeShown: false,
      selectedContentFeature: 'untimed_content',
      content_features: [{
        name: 'Süreli İçerik', type: 'timed_content', starts_at: 'null', ends_at: 'null'
      }, {
        name: 'Süresiz İçerik', type: 'untimed_content'
      }],
      contentsTimed: [],
      selectedEducationId: null,
      lastEndDate: null,
      lastStartDate: null,
      can_open_contents_in_order: true,
      qr_text: null,
      qor_code_modal_title: "Sınıf İçi Eğitim QR Kodu",
      isClassRoomEducationSelected: false,
      durationFeatureIsEnabled: false,
      showModalLearning: false,
      restUrl: "api/mail-template/assignment",
      textarea_length: 0,
      successMessage: null,
      errorMessages: [],
      cover_image: null,
      checked_users: [],
      checked_groups: [],
      name: null,
      is_compulsory: false,
      start_date: null,
      end_date: null,
      repeatable: false,
      repetition_no: 0,
      duration: 0,
      survey_id: null,
      appear_top: false,
      survey_required: true,
      highlight_education: false,
      has_feedbacks: false,
      description: null,
      gift_rule_id: 1,
      has_present: 0,
      active_content_id: null,
      gifts: [],
      educations: [],
      repetition_date: [],
      has_customized_template: false,
      send_mail_notification: false,
      send_mobile_notification: false,
      can_be_added_to_calendar: false,
      can_be_watched_again: false,
      mail_template: [],
      mail_template_codes: [],
      company: [],
      all_selected_educations: [],
      all_selected_gifts: [],
      selected_content: null,
      assignment_loading: false,
      remind_if_not_entered_x_days: 1,
      remind_if_not_entered_every_y_days: 1,
      remind_before_starting_x_days: 1,
      remind_before_starting_every_y_days: 1,
      remind_before_ending_x_days: 1,
      remind_before_ending_every_y_days: 1,
      isRemindEntered: false,
      isRemindStarting: false,
      isRemindEnding: false
    };
  },
  computed: {
    controlContentRequired() {
      if (!this.contentsTimed[this.selectedEducationId].starts_at || !this.contentsTimed[this.selectedEducationId].ends_at)
        return false
      return true
    },
    assignment_item: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ITEM];
      },
      set(value) {
      },
    },
    survey_items: {
      get() {
        return store.getters[SURVEY_MODULE_NAME + "/" + SURVEY_ITEMS];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    setMinMaxTime() {
      if (!this.lastEndDate) {
        this.lastEndDate = new Date();
      }
      if (!this.lastStartDate) {
        this.lastStartDate = new Date();
      }
      this.contentsTimed.forEach((content) => {
        if (this.lastEndDate.getTime() < new Date(content.ends_at).getTime()) {
          this.lastEndDate = new Date(content.ends_at);
        }
        if (this.lastStartDate.getTime() > new Date(content.starts_at).getTime())
          this.lastStartDate = new Date(content.starts_at);
      })
    },
    saveContentFeatures() {
      if (this.selectedContentFeature === 'timed_content') {
        this.setMinMaxTime();
      }
      this.contentsTimed[this.selectedEducationId].type = this.selectedContentFeature;
      this.selectedContentFeature === 'untimed_content';
      UIkit.modal("#modal_customize").hide();
    },
    showCustomize(id, index) {
      this.selectedEducationId = index;
      if (!this.contentsTimed[index]) {
        this.contentsTimed.push({
          id: id,
          type: 'untimed_content',
          starts_at: null,
          ends_at: null
        });
      } else {
        this.selectedContentFeature = this.contentsTimed[index].type
      }
      UIkit.modal("#modal_customize").show();
    },
    decideQrCodeForShowing: function () {

      const containsAnyClassRoomEducation = this.educations.some((education) => this.$t(education.name) === this.$t('documents.classroom_education'));
      if (containsAnyClassRoomEducation) {
        this.qrCodeCanBeShown = true;
        this.qr_text = `${window.location.origin}/#/assignments/${this.assignment_item.id}/classroom-education`;
        return;
      }
      this.qrCodeCanBeShown = false;
    },
    hideSomeInputsForClassRoomEducation: function () {
      // @todo: move central place
      this.isClassRoomEducationSelected = false;

      let isClassRoomEducationSelected = false;
      let repeatable = false;

      const containsAnyClassRoomEducation = this.educations.some(
        (education) => education.name === this.$t("documents.classroom_education")
      );
      if (containsAnyClassRoomEducation) {
        isClassRoomEducationSelected = true;
        repeatable = false;
      }

      this.repeatable = repeatable;
      this.isClassRoomEducationSelected = isClassRoomEducationSelected;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode === 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getMailTemplateData() {
      let self = this;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.restUrl,
          acceptPromise: false,
        })
        .then((result) => {
          if (typeof result !== 'undefined' && result.status) {
            self.company = result.data.company;
            self.mail_template = result.data.template;
            self.mail_template_codes = result.data.codes;
          }
        });
    },
    saveForm() {
      this.assignment_loading = true;
      let self = this;
      if (!this.isValidFields("ALL")) {
        return false;
      }

      let formData = new FormData();
      if (this.contentsTimed?.length > 0) {
        for (let i = 0; i < this.contentsTimed?.length; i++) {
          formData.append("contents[" + i + "][id]",
            this.contentsTimed[i].id)

          formData.append("contents[" + i + "][starts_at]",
            this.contentsTimed[i].starts_at)
          formData.append("contents[" + i + "][ends_at]",
            this.contentsTimed[i].ends_at)
          formData.append("contents[" + i + "][type]",
            this.contentsTimed[i].type);
        }
      } else {
        self.educations.forEach((element, index) => {
          formData.append("contents[" + index + "]", element.id);
        });
      }

      formData.append("name", self.name);
      formData.append("description", self.description);
      formData.append("has_present", self.has_present === true ? 1 : 0);
      formData.append("gift_rule_id", self.gift_rule_id);
      formData.append("is_user", self.checked_users.length > 0 ? 1 : 0);
      formData.append("users", self.checked_users);
      formData.append("is_group", self.checked_groups.length > 0 ? 1 : 0);
      formData.append("dates[" + 0 + "][start_date]", self.start_date);
      formData.append("dates[" + 0 + "][end_date]", self.end_date);
      formData.append("is_compulsory", self.is_compulsory ? 1 : 0);
      formData.append(
        "highlight_education",
        self.highlight_education === true ? 1 : 0
      );
      formData.append(
        "can_open_contents_in_order",
        self.can_open_contents_in_order
      );
      formData.append("repeatable", self.repeatable === true ? 1 : 0);
      formData.append(
        "repetition_no",
        self.repeatable ? self.repetition_no : 0
      );
      formData.append("duration", self.duration);
      formData.append("survey_required", self.survey_required ? 1 : 0);
      formData.append(
        "send_mail_notification",
        self.send_mail_notification === true ? 1 : 0
      );
      formData.append(
        "send_mobile_notification",
        self.send_mobile_notification === true ? 1 : 0
      );
      formData.append(
        "can_be_added_to_calendar",
        self.can_be_added_to_calendar === true ? 1 : 0
      );
      formData.append(
        "can_be_watched_again",
        self.can_be_watched_again === true ? 1 : 0
      );
      formData.append(
        "has_customized_template",
        self.has_customized_template === true ? 1 : 0
      );
      formData.append("appear_top", self.appear_top === true ? 1 : 0);
      formData.append("has_feedbacks", self.has_feedbacks === true ? 1 : 0);
      formData.append("cover_image", self.cover_image);

      if (self.has_customized_template === true) {
        formData.append("mail_template", self.mail_template.html);
      }

      if (self.survey_required === true) {
        formData.append("evaluation_survey_id", self.survey_id.id);
      }

      if (self.repeatable) {
        for (let i = 1; i <= self.repetition_no; i++) {
          if (
            self.repetition_date[i].start_date >
            self.repetition_date[i].end_date
          ) {
            this.errorMessages.push(
              "Başlangıç Tarihi Bitiş Tarihinden önce olmak zorunda."
            );
            return false;
          }
          formData.append(
            "dates[" + i + "][start_date]",
            self.repetition_date[i].start_date
          );
          formData.append(
            "dates[" + i + "][end_date]",
            self.repetition_date[i].end_date
          );
        }
      }
      self.gifts.forEach((element) => {
        formData.append("gifts[]", element.id);
      });
      self.checked_groups.forEach((element) => {
        formData.append("groups[]", element);
      });
      if (this.isRemindEntered) {
        formData.append("remind_if_not_entered_x_days", self.remind_if_not_entered_x_days);
        formData.append("remind_if_not_entered_every_y_days", self.remind_if_not_entered_every_y_days);
      }
      if (this.isRemindStarting) {
        formData.append("remind_before_starting_x_days", self.remind_before_starting_x_days);
        formData.append("remind_before_starting_every_y_days", self.remind_before_starting_every_y_days);
      }
      if (this.isRemindEnding) {
        formData.append("remind_before_ending_x_days", self.remind_before_ending_x_days);
        formData.append("remind_before_ending_every_y_days", self.remind_before_ending_every_y_days);
      }

      store
        .dispatch(ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_CREATE_ITEM, {
          url: ASSIGNMENT_BASE_URL,
          contents: formData,
        })
        .then((result) => {
          self.assignment_loading = false;
          self.scrollToTop();

          if (this.error) {
            this.errorMessages.push(this.error);
          }
          const containsClassRoomEducation = self.educations.some(
            (education) => this.$t(education.name) === this.$t("documents.classroom_education")
          );
          if (containsClassRoomEducation) {
            self.qr_text = `${window.location.origin}/#/assignments/${this.assignment_item.id}/classroom-education`;
            this.$modal.show("qr_code_for_classroom_education");

            return;
          }
          this.successMessage = self.$t("general.successfully_created");
          this.$router.push({ name: "company.assignments.list" });
        });
    },
    updateMailTemplate(newCode) {
      if (
        this.mail_template.is_linked &&
        (!newCode.includes("{SLINK}") || !newCode.includes("<!--BUTTON-->"))
      ) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(":NAME", "{SLINK}")
        );
        return false;
      }
      this.mail_template.html = newCode;
    },
    codeSlice() {
      if (this.mail_template_codes.length === undefined) return 0;
      return Math.ceil(this.mail_template_codes.length / 2);
    },
    copyCode(code) {
      const el = document.createElement("textarea");
      el.value = code;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    addContentToAssignment(item) {
      let edu_id = item.id;
      if (this.all_selected_educations.includes(edu_id)) {
        return false;
      }
      this.hideSomeInputsForClassRoomEducation();
      this.educations.push(item);
      this.all_selected_educations.push(edu_id);
    },
    addGiftToAssignment(item) {
      let gift_id = item.id;
      this.all_selected_gifts.push(gift_id);
      this.gifts.push(item);
    },
    removeGiftFromAssignment(id) {
      let gifts = this.gifts;
      let gifts_ids_all = this.all_selected_gifts;
      let pos = gifts
        .map(function (x) {
          return x;
        })
        .indexOf(id);
      let pos_all = gifts_ids_all
        .map(function (x) {
          return x;
        })
        .indexOf(id);
      gifts.splice(pos, 1);
      gifts_ids_all.splice(pos_all, 1);
      return true;
    },
    removeContentFromAssignment(education, index) {
      const edu_id = education.id;

      let educations = this.educations;
      let educations_ids_all = this.all_selected_educations;

      let pos_all = educations_ids_all.map((x) => x).indexOf(edu_id);

      let edu_pos = educations.map((x) => x.id).indexOf(edu_id);

      educations.splice(edu_pos, 1);
      educations_ids_all.splice(pos_all, 1);
      this.contentsTimed.splice(index, 1)
      this.hideSomeInputsForClassRoomEducation();
      return true;
    },
    async getSurveys() {
      let self = this;
      await this.$store.dispatch(SURVEY_MODULE_NAME + "/" + SURVEY_GET_ITEMS, {
        url: SURVEY_BASE_URL,
        filters: {
          is_active: 1,
          order: "asc",
          sort: "id",
          fields: "id,name,is_default",
        },
      });

      let survey = this.survey_items.find(
        (item) => parseInt(item.is_default) === 1
      );
      if (typeof survey !== "undefined") {
        self.survey_id = {
          id: survey.id,
          name: survey.name,
        };
      }
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getResultsAll() {
      this.getMailTemplateData();
      this.getSurveys();
    },
    processFile(event) {
      if (!event.target.files[0]) return;
      this.cover_image = event.target.files[0];
      let blob = URL.createObjectURL(this.cover_image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#cover_photo_input").click();
    },
    isValidFields(fields) {
      let self = this;

      self.resetMessages();

      if (fields !== "ALL") {
        fields--;
      }

      const indexOfEntryTab = 0;
      const indexOfContentTab = 1;
      const indexOfUsersTab = 2;
      const indexOfGiftTab = 3;

      if (fields === "ALL" || fields === indexOfEntryTab) {
        if (self.name == null || self.name?.length === 0) {
          return self.showFieldsFailedError(
            indexOfEntryTab,
            self.$t("validation.required_field").replace(":NAME", "Atama Adı")
          );
        }

        if (self.description === null || self.description?.length === 0) {
          return self.showFieldsFailedError(
            indexOfEntryTab,
            self.$t("validation.required_field").replace(":NAME", "Açıklama")
          );
        }

        if (self.cover_image === null) {
          return self.showFieldsFailedError(
            indexOfEntryTab,
            self.$t("content.required.cover_photo")
          );
        }
      }

      if ((fields === "ALL" || fields === indexOfContentTab) && self.all_selected_educations.length === 0) {
        return self.showFieldsFailedError(
          indexOfContentTab,
          self.$t("validation.required_field").replace(":NAME", "İçerik")
        );
      }

      if ((fields === "ALL" || fields === indexOfUsersTab) && self.checked_users.length === 0 && self.checked_groups.length === 0) {
        return self.showFieldsFailedError(
          indexOfUsersTab,
          self.$t("validation.required_field").replace(":NAME", "Kullanıcılar")
        );
      }

      if (this.$isGiftVisible && (fields === "ALL" || fields === indexOfGiftTab) && self.has_present && self.all_selected_gifts.length === 0) {
        return self.showFieldsFailedError(
          indexOfGiftTab,
          self.$t("validation.required_field").replace(":NAME", "Hediye")
        );
      }

      let indexOfSettingsTab = this.$isGiftVisible && fields === 3 ? 5 : 4;

      if (fields === "ALL" || fields === indexOfSettingsTab) {
        if (self.start_date == null) {
          return self.showFieldsFailedError(
            indexOfSettingsTab,
            self.$t("validation.required_field").replace(":NAME", "{{this.$t('general.start_day')}}")
          );
        }

        if (self.end_date == null) {
          return self.showFieldsFailedError(
            indexOfSettingsTab,
            self.$t("validation.required_field").replace(":NAME", "Son Tamamlama Tarihi")
          );
        }

        if (self.duration === "" || self.duration < 0) {
          self.duration = 0;
        }

        if (self.repeatable && self.repetition_no <= 0) {
          return self.showFieldsFailedError(
            indexOfSettingsTab,
            self.$t("validation.required_field").replace(":NAME", "Tekrar Sayısı")
          );
        }

        if (self.survey_required && (self.survey_id == null || !self.survey_id)) {
          return self.showFieldsFailedError(
            indexOfSettingsTab,
            self.$t("validation.required_field").replace(":NAME", "Anketi")
          );
        }

        if (self.has_customized_template && (self.mail_template.html == null || self.mail_template.html === "")) {
          return self.showFieldsFailedError(
            indexOfSettingsTab,
            self.$t("validation.required_field").replace(":NAME", "E-posta Şablonunu")
          );
        }
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);

      return false;
    },
    validateAndSwitch(index) {
      let areAllFieldsValid = this.isValidFields(index);
      if (areAllFieldsValid) {
        this.switcherChange(index);
      }

      const indexNumberOfSettingsTab = 4;
      if (index === indexNumberOfSettingsTab) {
        this.hideSomeInputsForClassRoomEducation();
      }
      // for qr code
      if (index === 5) {
        // this.decideQrCodeForShowing();
      }
    },
    switcherChange(index) {
      UIkit.switcher($("#main-switcher")[0]).show(index);
    },
    setMinimumDate(start_date = null) {
      let date = new Date();
      if (start_date) {
        let startDate = new Date(start_date);
        date = new Date(startDate.getTime() + 86400000); // + 1 day in ms
      }
      return date.toISOString().split("T")[0];
    },
  },
  mounted() {
    this.getResultsAll();
    if (this.$route.params.data) {
      this.selected_content = {
        id: this.$route.params.data.id ?? null,
        title: this.$route.params.data.title ?? null,
        name: this.$route.params.data.name ?? null
      };
    }
    if (this.selected_content) {
      this.addContentToAssignment(this.selected_content);
    }
    if (!this.$isGiftVisible) {
      this.has_present = 0;
    }
    setTimeout(() => {
      $(".selectpicker7").selectpicker();
    }, 400);

    this.$forceUpdate();

    setTimeout(() => {
      this.showModalLearning = true;
    }, 1000);

  },
  watch: {
    assignment_loading(val) {

    },
    description(val) {
      this.textarea_length = val.length;
    },
    repetition_no(val) {
      let date = new Date();
      let end_date = new Date(date.getTime() + 86400000)
        .toISOString()
        .split("T")[0];
      let start_date = date.toISOString().split("T")[0];
      if (val) {
        for (let index = 1; index <= val; index++) {
          this.repetition_date[index] = {};
          this.repetition_date[index].start_date = start_date;
          this.repetition_date[index].end_date = end_date;
        }
      }
    },
    survey_items(value) {
      if (value && value.length)
        setTimeout(() => {
          $(".selectpicker_survey").selectpicker();
        }, 500);
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
    can_be_added_to_calendar(val) {
      val ? this.send_mail_notification = val : null;
      this.can_be_added_to_calendar = val;
    },
    send_mail_notification(val) {
      !val ? this.can_be_added_to_calendar = val : null;
      this.send_mail_notification = val;
    },
    send_mobile_notification(val) {
      this.send_mobile_notification = val;
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_learning");
    if (modal) modal.$destroy(true);

    const modalGift = UIkit.modal("#modal_gifts");
    if (modalGift) modalGift.$destroy(true);
    const modalCustomize = UIkit.modal(" #modal_customize");
    if (modalCustomize) modalCustomize.$destroy(true);
  },
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-tab li.uk-active a {
  border-color: #eceff0;
}

.uk-tab-right::before {
  display: none;
}

.sidemenu li a {
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}

.row_number {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}

/* .header-a {
  cursor: default;
  pointer-events: none;
} */
</style>
